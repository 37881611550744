import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../state/auth/AuthContext';
import { PageSpinner } from '../components/PageSpinner';

const PrivateRoute: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const { role, status } = useAuth();

  if (status === 'PENDING') {
    return <PageSpinner />;
  }

  if (!role || role !== "admin") {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default PrivateRoute;
