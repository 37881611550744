// UserContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the user type
export interface User {
  username: string;
  name: string;
  password: string;
  email: string;
  company: string;
  phone: string;
  location: string;
  status: string,
}

// Define the context type
interface UserContextType {
  users: User[];
  user: User | null;
  fetchUsers: () => void;
  fetchUser: (username: string) => void;
  setUsers: (users: User[]) => void;
  setUser: (user: User | null) => void;
}

// Create the context with default values
const UserContext = createContext<UserContextType | undefined>(undefined);

// Function to fetch users from localStorage
const fetchUsersFromLocalStorage = (): User[] => {
  const users = localStorage.getItem('users');
  return users ? JSON.parse(users) : [];
};

// Function to fetch a single user from localStorage
const fetchUserFromLocalStorage = (username: string): User | null => {
  const users = fetchUsersFromLocalStorage();
  return users.find(user => user.name === username) || null;
};

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const fetchUsers = () => {
    const userList = fetchUsersFromLocalStorage();
    setUsers(userList);
  };

  const fetchUser = (username: string) => {
    const fetchedUser = fetchUserFromLocalStorage(username);
    setUser(fetchedUser);
  };

  return (
    <UserContext.Provider value={{ users, user, setUser, fetchUsers, fetchUser, setUsers }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
